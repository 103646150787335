<template>
  <v-menu
    id="menu-waste-list"
    transition="slide-y-transition"
    role="combobox"
    aria-expanded="false"
    open-delay="0"
    scroll-strategy="close">
    <template #activator="{ props }">
      <!-- Hero version -->
      <v-text-field
        v-if="definedProps.type === 'hero'"
        id="waste-dropdown-hero"
        v-bind="props"
        v-model="shownWasteType"
        :placeholder="smAndDown ? t('select_waste_type_short') : t('select_waste_type')"
        class="ml-2 pt-2 toolbar"
        hide-details
        variant="plain"
        append-inner-icon="mdi-chevron-down"
        readonly />

      <!-- Header version -->
      <v-text-field
        v-if="definedProps.type === 'header'"
        id="waste-dropdown-header"
        v-bind="props"
        v-model="shownWasteType"
        :placeholder="t('select_waste_type')"
        rounded="pill"
        max-width="392px"
        hide-details
        append-inner-icon="mdi-chevron-down"
        readonly />
      <!-- Text CTA version-->
      <v-text-field
        v-if="definedProps.type === 'textCta'"
        id="waste-dropdown-cta"
        v-bind="props"
        v-model="shownWasteType"
        aria-selected="false"
        :placeholder="t('select_waste_type')"
        dense
        hide-details
        append-inner-icon="mdi-chevron-down"
        readonly
        :error="definedProps.error"
        width="80%" />
      <!-- Cart version -->
      <ButtonShadow v-if="definedProps.type === 'cart'" color="white" class="" v-bind="props">
        <v-icon color="primary" class="mr-1">mdi-plus</v-icon>
        {{ t('add_container') }}
      </ButtonShadow>
    </template>
    <v-lazy :min-height="300" :options="{ threshold: 0.5 }" transition="fade-transition">
      <v-list
        height="300px"
        width="350px"
        min-width="100%"
        class="rounded-lg elevation-1"
        :class="definedProps.type">
        <v-list-item
          v-for="(fraction, index) in fractionsSorted"
          :key="index"
          :class="{ 'border-t': index !== 0 }"
          @click="goToFraction(fraction)">
          <v-list-item-title>
            <v-row class="pt-2p5 pb-2p5">
              <v-col cols="2" class="px-0">
                <NuxtImg
                  width="50"
                  provider="directus"
                  :src="fraction.image || ''"
                  :modifiers="{ format: 'auto', width: 50 }"
                  :alt="fraction?.image_alt_text || ''" />
              </v-col>
              <v-col class="pr-0">
                <p class="font-weight-bold">{{ fraction.description.substring(0, 25) }}</p>
                <span class="font-weight-thin opacity-40">
                  z.B.
                  {{ getFractionSubInfo(fraction) }}
                </span>
              </v-col>
            </v-row>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-lazy>
  </v-menu>
</template>
<script setup lang="ts">
import { useDisplay } from 'vuetify'
import type { Fraction } from '~/types/types'
const { smAndDown } = useDisplay()
const fractionStore = useFractionStore()
fractionStore.getFractionData()

const { fractionsSorted } = storeToRefs(fractionStore)

const { t } = useI18n()

const shownWasteType = ref('')
const emit = defineEmits(['selectedWaste'])

const definedProps = defineProps<{
  error?: boolean
  type?: string
}>()
const goToFraction = (fraction: Fraction) => {
  shownWasteType.value = fraction.description
  emit('selectedWaste', fraction)
}
const getFractionSubInfo = (fraction: Fraction) => {
  if (fraction?.allowed_items?.length) {
    let allowedItems = ''
    if (fraction.allowed_items.length > 1) {
      allowedItems = fraction.allowed_items.join(', ')
    } else {
      allowedItems = fraction.allowed_items[0]
    }
    return allowedItems.length > 20 ? `${allowedItems.substring(0, 19)}...` : allowedItems
  }
  return ''
}
</script>
<style lang="scss" scoped>
.v-list.rounded-lg.hero {
  margin-left: -97px;
  @media (min-width: 1920px) {
    margin-left: -12px;
  }
  @media (min-width: 2560px) {
    margin-left: -26px;
  }
}

.v-lazy:deep(::-webkit-scrollbar) {
  display: none;
}
.v-lazy {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.toolbar {
  min-height: 78px;
}
.toolbar:deep(input::placeholder) {
  color: black;
  opacity: 1;
  font-weight: 700;
  font-size: 1.1rem;
}
.toolbar:deep(input:focus::placeholder) {
  opacity: 0.2;
}
@media (max-width: 599px) {
  .toolbar:deep(input::placeholder) {
    font-size: 14px;
  }
}
</style>
